/* eslint-disable max-lines */
import { AddUserIcon } from 'assets/icons/AddUserIcon';
import { ClientIcon } from 'assets/icons/ClientIcon';
import { ClientRequestNotificationIcon } from 'assets/icons/ClientRequestNotificationIcon/ClientRequestNotificationIcon';
import { HistoryIcon } from 'assets/icons/HistoryIcon';
import { HomeIcon } from 'assets/icons/HomeIcon';
import { IconPossiblyLateralProps } from 'assets/icons/types';
import {
  BusinessAdminClientRequestsPage,
  ClientCompaniesPage,
  ClientRequestsPage,
  HistoryPage,
  HomePage,
  SitesAndDocumentsPage,
  UsersPage,
} from 'pages/lazyLoadedPages';
import { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { ElementType } from 'react-transition-group/node_modules/@types/react';
import { getUserRoleCode, isBackOfficeUser, UserApplication } from 'redux/User/selectors';
import { AuthorizationConfig } from 'redux/User/types';
import { ROUTES } from 'routing/routes';
import {
  GOOGLE_ANALYTICS_ACTIONS,
  GOOGLE_ANALYTICS_CATEGORIES,
  GOOGLE_ANALYTICS_LABELS,
  GoogleAnalyticsTagConfig,
} from 'services/analytics/config';
import { PLATFORM_LEGAL_CORPORATION_LABEL } from 'services/api/constants';
import { CLIENT_ADMIN_ROLE_CODE } from 'services/api/roles/types';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

//import { GoToFileIcon } from '../assets/icons/GoToFileIcon';
import { MapMarkerIcon } from '../assets/icons/MapMarkerIcon';

export type LateralMenuLinkConfig = {
  title: string;
  disabledOnActive?: boolean;
};

export type HomePageMenuSectionConfig = {
  title: string;
  descriptionItems: string[];
};

export type BaseMenuPageConfig = {
  path: string;
  linkPath?: string;
  search?: string;
  icon: ComponentType<IconPossiblyLateralProps>;
  googleAnalyticsTagConfig: GoogleAnalyticsTagConfig;
};

type MenuPageConfig = BaseMenuPageConfig &
  AuthorizationConfig & {
    lateralMenuLinkConfig: LateralMenuLinkConfig;
    homePageMenuSectionConfig?: HomePageMenuSectionConfig;
    pageComponent: ElementType;
  };

export const useMenuPageConfigs = (): MenuPageConfig[] => {
  const lateralMenuIntl = useIntlByKey('menu');
  const homePageIntl = useIntlByKey('home');
  const userRoleCode = useSelector(getUserRoleCode);
  const isBackoffice = useSelector(isBackOfficeUser);

  const getDescriptionItems = (sectionKey: string, descriptionItemKeys: string[]) => {
    return descriptionItemKeys.map((descriptionItemKey) => {
      return homePageIntl(`menu-sections.${sectionKey}.description-items.${descriptionItemKey}`, {
        legalCorporationName: PLATFORM_LEGAL_CORPORATION_LABEL.toUpperCase(),
      });
    });
  };

  return [
    {
      path: ROUTES.HOME,
      icon: HomeIcon,
      applications: [UserApplication.FRONT_OFFICE, UserApplication.BACK_OFFICE],
      googleAnalyticsTagConfig: {
        action: GOOGLE_ANALYTICS_ACTIONS.HOME_PAGE_VIEW,
        label: GOOGLE_ANALYTICS_LABELS.PAGE_VIEW,
        category: GOOGLE_ANALYTICS_CATEGORIES.HOME,
      },
      pageComponent: HomePage,
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('home'),
      },
    },
    {
      path: ROUTES.CLIENT_REQUESTS,
      linkPath: ROUTES.CLIENT_REQUESTS,
      search: ROUTES.CLIENT_REQUESTS_SEARCH_SUFFIX.OTHER_REQUESTS_TAB,
      icon: ClientRequestNotificationIcon,
      applications: [UserApplication.FRONT_OFFICE],
      googleAnalyticsTagConfig: {
        action: GOOGLE_ANALYTICS_ACTIONS.CLIENT_REQUESTS_PAGE_VIEW,
        label: GOOGLE_ANALYTICS_LABELS.PAGE_VIEW,
        category: GOOGLE_ANALYTICS_CATEGORIES.CLIENT_REQUESTS,
      },
      pageComponent: ClientRequestsPage,
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('client-requests'),
        disabledOnActive: true,
      },
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.client-requests.title'),
        descriptionItems: getDescriptionItems('client-requests', [
          'submit-new-request',
          'follow-your-current-requests-exchanges',
          'find-your-exchanged-documents',
        ]),
      },
    },
    // {
    //   path: `${ROUTES.CLIENT_REQUESTS}`,
    //   linkPath: `${ROUTES.CLIENT_REQUESTS}`,
    //   search:
    //     ROUTES.CLIENT_REQUESTS_SEARCH_SUFFIX
    //       .INERT_WASTE_APPLICATION_TAB_WITH_CREATE_SIDE_PANEL_OPEN,
    //   icon: GoToFileIcon,
    //   applications: [UserApplication.FRONT_OFFICE],
    //   googleAnalyticsTagConfig: {
    //     action: GOOGLE_ANALYTICS_ACTIONS.CLIENT_REQUESTS_PAGE_VIEW,
    //     label: GOOGLE_ANALYTICS_LABELS.PAGE_VIEW,
    //     category: GOOGLE_ANALYTICS_CATEGORIES.CLIENT_REQUESTS,
    //   },
    //   pageComponent: ClientRequestsPage,
    //   lateralMenuLinkConfig: {
    //     title: lateralMenuIntl('new-inert-waste-application-for-prior-acceptance'),
    //     disabledOnActive: true,
    //   },
    //   homePageMenuSectionConfig: {
    //     title: homePageIntl('menu-sections.new-inert-waste-application-for-prior-acceptance.title'),
    //     descriptionItems: [],
    //   },
    // },
    {
      path: ROUTES.BUSINESS_ADMIN_CLIENT_REQUESTS,
      linkPath: `${ROUTES.BUSINESS_ADMIN_CLIENT_REQUESTS}`,
      search: ROUTES.CLIENT_REQUESTS_SEARCH_SUFFIX.OTHER_REQUESTS_TAB,
      icon: ClientRequestNotificationIcon,
      applications: [UserApplication.BACK_OFFICE],
      googleAnalyticsTagConfig: {
        action: GOOGLE_ANALYTICS_ACTIONS.BUSINESS_ADMIN_CLIENT_REQUESTS_PAGE_VIEW,
        label: GOOGLE_ANALYTICS_LABELS.PAGE_VIEW,
        category: GOOGLE_ANALYTICS_CATEGORIES.CLIENT_REQUESTS,
      },
      pageComponent: BusinessAdminClientRequestsPage,
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('business-admin-client-requests'),
        disabledOnActive: true,
      },
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.business-admin-client-requests.title'),
        descriptionItems: getDescriptionItems('business-admin-client-requests', [
          'submit-new-request',
          'follow-your-current-requests-exchanges',
          'find-your-exchanged-documents',
        ]),
      },
    },
    // {
    //   path: ROUTES.BUSINESS_ADMIN_CLIENT_REQUESTS,
    //   linkPath: ROUTES.BUSINESS_ADMIN_CLIENT_REQUESTS,
    //   search: ROUTES.CLIENT_REQUESTS_SEARCH_SUFFIX.INERT_WASTE_APPLICATION_TAB,
    //   icon: GoToFileIcon,
    //   applications: [UserApplication.BACK_OFFICE],
    //   googleAnalyticsTagConfig: {
    //     action: GOOGLE_ANALYTICS_ACTIONS.BUSINESS_ADMIN_CLIENT_REQUESTS_PAGE_VIEW,
    //     label: GOOGLE_ANALYTICS_LABELS.PAGE_VIEW,
    //     category: GOOGLE_ANALYTICS_CATEGORIES.CLIENT_REQUESTS,
    //   },
    //   pageComponent: BusinessAdminClientRequestsPage,
    //   lateralMenuLinkConfig: {
    //     title: lateralMenuIntl('inert-waste-application-for-prior-acceptance'),
    //     disabledOnActive: true,
    //   },
    //   homePageMenuSectionConfig: {
    //     title: homePageIntl('menu-sections.inert-waste-application-for-prior-acceptance.title'),
    //     descriptionItems: [],
    //   },
    // },
    {
      path: ROUTES.HISTORY,
      icon: HistoryIcon,
      applications: [UserApplication.FRONT_OFFICE],
      googleAnalyticsTagConfig: {
        action: GOOGLE_ANALYTICS_ACTIONS.HISTORY_PAGE,
        label: GOOGLE_ANALYTICS_LABELS.PAGE_VIEW,
        category: GOOGLE_ANALYTICS_CATEGORIES.DELIVERY_NOTES,
      },
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('history'),
      },
      pageComponent: HistoryPage,
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.history.title'),
        descriptionItems: getDescriptionItems('history', [
          userRoleCode === CLIENT_ADMIN_ROLE_CODE
            ? 'find-your-delivery-notes-and-invoices'
            : 'find-your-delivery-notes',
          'export-your-delivery-notes',
          'see-and-download-associated-pdfs',
        ]),
      },
    },
    {
      path: ROUTES.CLIENT_COMPANIES,
      icon: ClientIcon,
      applications: [UserApplication.BACK_OFFICE],
      googleAnalyticsTagConfig: {
        action: GOOGLE_ANALYTICS_ACTIONS.CLIENT_COMPANIES_PAGE_VIEW,
        label: GOOGLE_ANALYTICS_LABELS.PAGE_VIEW,
        category: GOOGLE_ANALYTICS_CATEGORIES.CLIENT_COMPANIES,
      },
      pageComponent: ClientCompaniesPage,
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('client-companies'),
      },
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.client-companies.title'),
        descriptionItems: getDescriptionItems('client-companies', [
          'open-client-workspace-cancel-invitation',
          'access-client-workspace',
          'submit-new-request-to-client',
          'access-client-exchange-history',
        ]),
      },
    },
    {
      path: ROUTES.SITES_AND_DOCUMENTS,
      icon: MapMarkerIcon,
      applications: [UserApplication.FRONT_OFFICE, UserApplication.BACK_OFFICE],
      googleAnalyticsTagConfig: {
        action: GOOGLE_ANALYTICS_ACTIONS.SITE_DOCUMENTS_PAGE_VIEW,
        label: GOOGLE_ANALYTICS_LABELS.PAGE_VIEW,
        category: GOOGLE_ANALYTICS_CATEGORIES.SITE_AND_DOCUMENT,
      },
      pageComponent: SitesAndDocumentsPage,
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('sites-and-documents'),
      },
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.sites-and-documents.title'),
        descriptionItems: getDescriptionItems('sites-and-documents', [
          'find-a-site',
          ...(isBackoffice
            ? ['see-available-documents-by-sites', 'manage-documents']
            : [
                'retrieve-document-models',
                'retrieve-site-documents',
                'see-and-download-documents',
              ]),
        ]),
      },
    },
    {
      path: ROUTES.USERS,
      icon: AddUserIcon,
      applications: [UserApplication.FRONT_OFFICE],
      roles: [CLIENT_ADMIN_ROLE_CODE],
      isAllowedForInternalCompany: true,
      pageComponent: UsersPage,
      googleAnalyticsTagConfig: {
        action: GOOGLE_ANALYTICS_ACTIONS.CLIENT_COMPANY_USERS_PAGE_VIEW,
        label: GOOGLE_ANALYTICS_LABELS.PAGE_VIEW,
        category: GOOGLE_ANALYTICS_CATEGORIES.CLIENT_COMPANY_USERS,
      },
      lateralMenuLinkConfig: {
        title: lateralMenuIntl('manage-users'),
      },
      homePageMenuSectionConfig: {
        title: homePageIntl('menu-sections.manage-users.title'),
        descriptionItems: getDescriptionItems('manage-users', [
          'invite-new-users-for-this-account',
          'update-or-delete-existing-users',
        ]),
      },
    },
  ];
};
